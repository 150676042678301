import React, { useEffect, useState } from "react";
import { useToastProvider } from "../../providers/useToastProvider";
import protectedAPI from "../../config/protectedAPI";
import { UPLOADS_URL } from "../../config/api";
import { Sidebar } from "primereact/sidebar";
import { TabMenu } from "primereact/tabmenu";
import { InputTextarea } from "primereact/inputtextarea";

function ViewReviewRequestModal(props) {
  const {
    visibleReviewRequestModal,
    toggleReviewRequestModalVisibility,
    selectedTestCase,
    approvalRequestType,
    selectedReviewRequest,
    setSelectedReviewRequest,
    testcases,
    setTestCases,
    setSelectedTestCase,
    setReviewRequestsOfType2,
  } = props;

  const showToast = useToastProvider();

  const saveBtnRef = React.createRef();

  const [userId, setUserId] = useState(null);

  const [comments, setComments] = useState([]);
  const [typingComment, setTypingComment] = useState("");

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabItems = [
    { label: "Request Details", icon: "pi pi-fw pi-align-left" },
    { label: "Comments", icon: "pi pi-fw pi-comment" },
  ];

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-UK", options);
  };

  const getCommentsByRequestId = async (requestId) => {
    try {
      const response = await protectedAPI.get(
        "comment/getCommentsByRequestId/" + requestId
      );
      if (response.status === 200) {
        setComments(response.data);
        return;
      }
    } catch (error) {
      setComments([]);
      if (error.response?.status !== 404)
        showToast({
          type: error.response?.status < 500 ? "warn" : "error",
          detail:
            error.response?.data?.message ||
            "An error occurred. Please try again.",
        });
    }
  };

  const addComment = async () => {
    try {
      if (!typingComment) {
        showToast({
          type: "warn",
          detail: "Please type your comment.",
        });
        return;
      }

      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const response = await protectedAPI.post("comment/create", {
        requestId: selectedReviewRequest?.id,
        comment: typingComment,
      });

      if (response.status === 201) {
        // showToast({
        //   type: "success",
        //   detail: "Comment added successfully.",
        // });
        setTypingComment("");
        getCommentsByRequestId(selectedReviewRequest?.id);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Send";
    }
  };

  const updateStatus = async (requestId, status) => {
    try {
      const response = await protectedAPI.post(
        "reviewRequest/updateStatus/" + requestId + "/" + status
      );
      if (response.status === 200) {
        showToast({
          type: "success",
          detail: response.data.message,
        });
        const updatedRequests = response.data.updatedRequests;
        const updatedTestcase = response.data.updatedTestcase;

        const currentRequest = updatedRequests.find(
          (request) => request.id === requestId
        );

        setSelectedReviewRequest(currentRequest);

        const updatedTestCases = testcases.map((testCase) => {
          if (testCase.id === selectedTestCase.id) {
            const s_testcase = updatedTestcase;
            setSelectedTestCase(s_testcase);
            return s_testcase;
          }
          return testCase;
        });
        setTestCases(updatedTestCases);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserId(user.id);
  }, []);

  useEffect(() => {
    if (selectedReviewRequest) {
      getCommentsByRequestId(selectedReviewRequest.id);
    }
    // console.log("selectedReviewRequest", selectedReviewRequest);
  }, [selectedReviewRequest]);

  return (
    <Sidebar
      header={() => {
        return (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm border me-3"
              onClick={toggleReviewRequestModalVisibility}
            >
              <i className="pi pi-arrow-left"></i>
            </button>
            <span className="text-white">Review Request Details</span>
          </div>
        );
      }}
      position="right"
      visible={visibleReviewRequestModal}
      onHide={toggleReviewRequestModalVisibility}
      showCloseIcon={false}
      style={{ width: "40rem" }}
    >
      <div className="d-flex flex-column w-100">
        <TabMenu
          model={tabItems}
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
        />
        {activeTabIndex === 0 && (
          <>
            {/* <div className="d-flex align-items-center justify-content-between border rounded p-2 dark-gradient-bg"> */}
            <div>
              <small>
                Status :{" "}
                <small
                  className={`rounded-pill px-2 text-white shadow fw-bold`}
                  style={{
                    fontSize: "11px",
                    backgroundColor:
                      selectedReviewRequest?.status == 0
                        ? "#f0ad4e"
                        : selectedReviewRequest?.status == 1
                        ? "#28a745"
                        : "#dc3545",
                    border:
                      "1px solid " +
                      (selectedReviewRequest?.status == 0
                        ? "#f0ad4e"
                        : selectedReviewRequest?.status == 1
                        ? "#28a745"
                        : "#dc3545"),
                  }}
                >
                  {selectedReviewRequest?.status == 0
                    ? "Pending"
                    : selectedReviewRequest?.status == 1
                    ? "Approved"
                    : "Rejected"}
                </small>
              </small>
            </div>
            <div>
              <small className="mt-1">
                Status Updated At :{" "}
                <small className="fw-bold">
                  {selectedReviewRequest?.statusUpdatedAt != null
                    ? formatDate(selectedReviewRequest?.statusUpdatedAt)
                    : "N/A"}
                </small>
              </small>
            </div>
            <div>
              <small className="mt-1">
                Status Updated By :{" "}
                <small className="fw-bold">
                  {selectedReviewRequest?.statusUpdatedByDetails != null
                    ? selectedReviewRequest?.statusUpdatedByDetails.fullname ||
                      selectedReviewRequest?.statusUpdatedByDetails.nickname
                    : "N/A"}
                </small>
              </small>
            </div>
            <div>
              <small className="mt-1">
                Requested At :{" "}
                <small className="fw-bold">
                  {formatDate(selectedReviewRequest?.createdAt)}
                </small>
              </small>
            </div>
            {selectedReviewRequest?.status == 0 && (
              // <div>
              //   <button className="btn btn-sm btn-danger">
              //     <i className="pi pi-trash"></i>
              //   </button>
              // </div>
              <></>
            )}
            {/* </div> */}
            <div className="mt-3">
              <span>Message</span>
              <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2 mt-2">
                {selectedReviewRequest?.message || "No message available."}
              </div>
            </div>
            {selectedReviewRequest?.status == 0 &&
            selectedReviewRequest?.assignees?.some(
              (assignee) => assignee.userId === userId
            ) ? (
              <div className="mt-3">
                <span>Actions</span>
                <div className="mt-2">
                  <button
                    className="btn btn-sm btn-success px-3 me-1"
                    onClick={() => updateStatus(selectedReviewRequest?.id, 1)}
                  >
                    <i className="pi pi-check"></i> Approve
                  </button>
                  <button
                    className="btn btn-sm btn-danger px-3"
                    onClick={() => updateStatus(selectedReviewRequest?.id, 2)}
                  >
                    <i className="pi pi-times"></i> Reject
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        {activeTabIndex === 1 && (
          <div
            className="d-flex flex-column"
            style={{ height: "calc(100vh - 150px)" }}
          >
            <div
              className="d-flex flex-column pe-2"
              style={{ overflowY: "auto", height: "calc(100vh - 290px)" }}
            >
              {comments.map((comment, index) => (
                <div
                  key={index}
                  className={
                    "d-flex flex-column border-2 rounded ps-3 py-2 border-" +
                    (comment.userId === userId ? "end" : "start") +
                    " border-secondary dark-gradient-bg mb-2 w-75" +
                    (comment.userId === userId ? " ms-auto" : "")
                  }
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img
                        src={
                          UPLOADS_URL +
                          "employees/" +
                          (comment?.userDetails?.image || "no-user.png")
                        }
                        alt="profile"
                        className="rounded-circle me-2"
                        width={15}
                        height={15}
                      />
                      <small className="fw-medium text-secondary">
                        {comment?.userDetails?.fullname ||
                          comment?.userDetails?.nickname}
                      </small>
                    </div>
                    <div className="me-2">
                      <small
                        className="text-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {formatDate(comment?.createdAt)}
                      </small>
                    </div>
                  </div>
                  <div className="mt-3 me-2">
                    <p
                      className="text-wrap fw-bold"
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      {comment?.comment}
                    </p>
                  </div>
                </div>
              ))}
              {comments.length === 0 && (
                <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2">
                  No comments available.
                </div>
              )}
            </div>
            <div className="mt-auto">
              <div className="d-flex flex-column">
                <InputTextarea
                  value={typingComment}
                  onChange={(e) => setTypingComment(e.target.value)}
                  placeholder="Type your comment here..."
                  className="w-100"
                  rows={3}
                />
                <div className="d-flex justify-content-between mt-2">
                  <button
                    ref={saveBtnRef}
                    className="btn btn-sm btn-info px-3"
                    onClick={addComment}
                    onKeyUp={(e) => {
                      if (e.ctrlKey && e.key === "Enter") {
                        addComment();
                      }
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Sidebar>
  );
}

export default ViewReviewRequestModal;
