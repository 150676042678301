import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useToastProvider } from "../../providers/useToastProvider";
import protectedAPI from "../../config/protectedAPI";
import { UPLOADS_URL } from "../../config/api";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";

function CreateRequestApprovalModal(props) {
  const {
    visibleRequestApprovalModal,
    toggleRequestApprovalModalVisibility,
    selectedTestCase,
    approvalRequestType,
    testcases,
    setTestCases,
    setSelectedTestCase,
    checkedTestCases,
  } = props;

  const showToast = useToastProvider();

  const saveBtnRef = React.createRef();

  const [projectManagers, setProjectManagers] = useState([]);
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);
  const [message, setMessage] = useState("");

  const getProjectManagersByProjectId = async (
    projectId,
    _approvalRequestType
  ) => {
    try {
      let url = "project/getProjectManagersByProjectId/" + projectId;
      if (_approvalRequestType == 2) {
        url = "project/getDirectorByProjectId/" + projectId;
      }

      const response = await protectedAPI.get(url);
      if (response.status === 200) {
        setProjectManagers(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const submitRequest = async (
    e,
    selectedTestCaseId,
    showSuccessMessage = true
  ) => {
    try {
      if (!selectedTestCaseId) return;

      if (!selectedProjectManagers?.length) {
        showToast({
          type: "warn",
          detail: "Please select at least one project manager.",
        });
        return false;
      }

      e.target.disabled = true;
      e.target.innerText = "Please wait...";

      const _selectedUserIds = selectedProjectManagers.map((user) => user.id);

      const response = await protectedAPI.post("reviewRequest/create", {
        testCaseId: selectedTestCaseId?.id,
        message,
        requestType: approvalRequestType,
        requestFrom: JSON.stringify(_selectedUserIds),
      });
      if (response.status === 201) {
        const currentRequests = response.data.currentRequests;
        const updatedTestCases = testcases.map((testCase) => {
          if (testCase.id === selectedTestCaseId?.id) {
            const s_testcase = {
              ...testCase,
              reviewRequests: currentRequests,
            };
            setSelectedTestCase(s_testcase);
            return s_testcase;
          }
          return testCase;
        });
        setTestCases(updatedTestCases);

        if (showSuccessMessage) {
          showToast({
            type: "success",
            detail: response.data.message,
          });
          setMessage("");
          setSelectedProjectManagers([]);
          toggleRequestApprovalModalVisibility();
        } else {
          return true;
        }
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
      if (!showSuccessMessage) {
        return false;
      }
    } finally {
      e.target.disabled = false;
      e.target.innerText = "Submit Request";
    }
  };

  const _submitRequest = async (e) => {
    if (checkedTestCases?.length) {
      let succededCount = 0;
      await Promise.all(
        checkedTestCases.map(async (testCase) => {
          const res = await submitRequest(e, testCase, false);
          if (res) succededCount++;
        })
      );
      if (succededCount > 0) {
        showToast({
          type: "success",
          detail: `${succededCount} out of ${checkedTestCases.length} requests submitted successfully.`,
        });
      }
      setMessage("");
      setSelectedProjectManagers([]);
      toggleRequestApprovalModalVisibility();
    } else {
      await submitRequest(e, selectedTestCase, true);
    }
  };

  const projectManagerTemplate = (option) => {
    return (
      <>
        <div className="d-flex">
          <img
            src={UPLOADS_URL + "employees/" + (option?.image || "no-user.png")}
            alt=""
            className="rounded-circle me-2"
            width={20}
          />
          <span>{option?.fullname || option?.nickname}</span>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (selectedTestCase) {
      getProjectManagersByProjectId(
        selectedTestCase?.projectId,
        approvalRequestType
      );
      setMessage("");
    }
  }, [selectedTestCase, approvalRequestType]);

  return (
    <Dialog
      header={
        approvalRequestType == 1
          ? "Request Manager Approval"
          : "Request Director Approval"
      }
      visible={visibleRequestApprovalModal}
      style={{ width: "50vw" }}
      onHide={toggleRequestApprovalModalVisibility}
    >
      <div className="d-flex flex-column w-100">
        <span className="mb-2">Request From</span>
        <MultiSelect
          value={selectedProjectManagers}
          onChange={(e) => setSelectedProjectManagers(e.value)}
          options={projectManagers}
          itemTemplate={projectManagerTemplate}
          filter
          optionLabel={(option) => option?.fullname || option?.nickname}
          className="w-100 mb-4"
        />
      </div>
      <div className="mb-2">
        <span>Message</span>
      </div>
      <InputTextarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here..."
        className="w-100"
        autoResize
        rows={5}
      />
      <div className="d-flex justify-content-end mt-3">
        <button
          className="btn btn-sm btn-success px-3 me-2"
          ref={saveBtnRef}
          onClick={(e) => _submitRequest(e)}
        >
          Submit Request
        </button>
      </div>
    </Dialog>
  );
}

export default CreateRequestApprovalModal;
