import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { Timeline } from "primereact/timeline";

function HistoryModal(props) {
  const { showHistoryModal, setShowHistoryModal, selectedTestcase } = props;
  const showToast = useToastProvider();
  const [history, setHistory] = useState([]);

  const getHistory = async (testcase) => {
    try {
      const response = await protectedAPI.get(
        `activityLog/getActivityByTestcaseId/${testcase.id}`
      );
      if (response.status === 200) {
        let _history = response.data.map((item) => {
          return {
            data: item,
            color:
              item.type === "TESTCASE_CREATED"
                ? "#007ad9"
                : item.type === "TESTCASE_UPDATED"
                ? "#00b2d4"
                : "#00d977",
            icon:
              item.type === "TESTCASE_CREATED"
                ? "pi pi-plus"
                : item.type === "TESTCASE_UPDATED"
                ? "pi pi-refresh"
                : "pi pi-check",
          };
        });
        setHistory(_history);
        // console.log(response.data);
      }
    } catch (error) {
      setHistory([]);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const customizedMarker = (item) => {
    return (
      <span
        className="p-2 rounded-circle py-1 text-white"
        style={{ backgroundColor: item.color }}
      >
        <i className={item.icon}></i>
      </span>
    );
  };

  const customizedContent = (item) => {
    return (
      <div className="d-flex flex-column">
        <small className="text-info">{item.data.formattedTime}</small>
        <p>
          <strong>{item.data.userDetails.nickname}</strong>{" "}
          {item.data.type === "TESTCASE_CREATED"
            ? "created"
            : item.data.type === "TESTCASE_UPDATED"
            ? "updated"
            : "approved"}{" "}
          this testcase.
        </p>
        {item.data.type === "TESTCASE_UPDATED" && (
          <p style={{fontSize: "11px"}} className="bg-warning-subtle border border-warning p-2 py-1 rounded">
            {item.data.attribute} {" => "} 
            {item.data.value.length > 50 ? item.data.value.substring(0, 50) + "..." : item.data.value}
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (showHistoryModal && selectedTestcase) {
      getHistory(selectedTestcase);
    }
  }, [selectedTestcase, showHistoryModal]);

  return (
    <Dialog
      header={
        "Testcase History ( " + (selectedTestcase?.ref || "Unknown") + " )"
      }
      visible={showHistoryModal}
      style={{ width: "50vw" }}
      onHide={() => {
        if (!showHistoryModal) return;
        setShowHistoryModal(false);
      }}
    >
      {history.length ? (
        <Timeline
          value={history}
          align="alternate"
          className="customized-timeline"
          marker={customizedMarker}
          content={customizedContent}
        />
      ) : (
        <>
          <div className="text-center">
            <i className="pi pi-info-circle fs-1 text-body-tertiary"></i>
            <p className="text-body-tertiary">No history found.</p>
          </div>
        </>
      )}
    </Dialog>
  );
}

export default HistoryModal;
