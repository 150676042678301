import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useRef, useState } from "react";
import RequestApprovalModal from "./CreateRequestApprovalModal";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import ViewReviewRequestModal from "./ViewReviewRequestModal";
import { TabMenu } from "primereact/tabmenu";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import ViewScreenshot from "./ViewScreenshot";

function TestCaseSidePanel(props) {
  const {
    visible,
    toggleSidePanelVisibility,
    selectedTestCase,
    testcases,
    setTestCases,
    setSelectedTestCase,
    projects,
    modules,
    riskCategories,
    severityOptions,
    dropdownItemTemplate,
    dropdownValueTemplate,
    severityDropdownItemTemplate,
  } = props;

  const showToast = useToastProvider();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabItems = [
    { label: "Testcase", icon: "pi pi-fw pi-info-circle" },
    { label: "Review Requests", icon: "pi pi-fw pi-verified" },
    { label: "Screenshots", icon: "pi pi-fw pi-image" },
  ];

  //* Testcase
  const [selectedProject, setSelectedProject] = useState(null);
  const [filteredModules, setFilteredModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedRiskCategory, setSelectedRiskCategory] = useState(null);
  const _severityOptions = severityOptions.slice(1);
  const [selectedSeverity, setSelectedSeverity] = useState(null);
  const [possibleRisk, setPossibleRisk] = useState("");
  const [observation, setObservation] = useState("");
  const [implications, setImplications] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const updateBtnRef = useRef();

  //* Review Request
  const [user, setUser] = useState(null);
  const [selectedReviewRequest, setSelectedReviewRequest] = useState(null);

  const [visibleRequestApprovalModal, setVisibleRequestApprovalModal] =
    useState(false);
  const [visibleReviewRequestModal, setVisibleReviewRequestModal] =
    useState(false);
  const [approvalRequestType, setApprovalRequestType] = useState(1);
  const [reviewRequestsOfType2, setReviewRequestsOfType2] = useState([]);
  const [screenshots, setScreenshots] = useState([]);

  const [viewScreenshotDialogVisible, setViewScreenshotDialogVisible] =
    useState(false);
  const [downloadedScreenshot, setDownloadedScreenshot] = useState(null);

  const toggleRequestApprovalModalVisibility = () => {
    setVisibleRequestApprovalModal(!visibleRequestApprovalModal);
  };
  const toggleReviewRequestModalVisibility = () => {
    setApprovalRequestType(1);
    setVisibleReviewRequestModal(!visibleReviewRequestModal);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-UK", options);
  };

  const getReviewRequestsByTestcaseId = async (testcaseId) => {
    try {
      const response = await protectedAPI.get(
        "reviewRequest/getReviewRequestsByTestcaseId/" + testcaseId
      );
      if (response.status === 200) {
        const reviewRequests = response.data;

        const reviewRequestsOfType2 = reviewRequests.filter(
          (request) => request.requestType == 2
        );
        setReviewRequestsOfType2(reviewRequestsOfType2);

        const updatedTestCases = testcases.map((testcase) => {
          if (testcase.id === testcaseId) {
            testcase.reviewRequests = reviewRequests;
          }
          return testcase;
        });
        setTestCases(updatedTestCases);
        // setSelectedTestCase({
        //   ...selectedTestCase,
        //   reviewRequests: reviewRequests,
        // }); //* this makes a loop
      }
    } catch (error) {
      if (error.response?.status !== 404)
        showToast({
          type: error.response?.status < 500 ? "warn" : "error",
          detail:
            error.response?.data?.message ||
            "An error occurred. Please try again.",
        });
    }
  };

  const getScreenshotsByTestcaseId = async (testcaseId) => {
    try {
      const response = await protectedAPI.get(
        "screenshot/getScreenshotsByTestcaseId/" + testcaseId
      );
      if (response.status === 200) {
        const screenshots = response.data;
        setScreenshots(screenshots);
      }
    } catch (error) {
      setScreenshots([]);
      if (error.response?.status !== 404)
        showToast({
          type: error.response?.status < 500 ? "warn" : "error",
          detail:
            error.response?.data?.message ||
            "An error occurred. Please try again.",
        });
    }
  };

  const downloadScreenshot = async (screenshot, e, save = false) => {
    try {
      e.target.disabled = true;
      e.target.innerText = "Please wait...";
      const response = await protectedAPI.get(
        "screenshot/downloadScreenshot/" + screenshot.id
      );
      if (response.status === 200) {
        if (!save) {
          setDownloadedScreenshot({
            filename: screenshot.filename,
            image: response.data.base64,
          });
          setViewScreenshotDialogVisible(true);
        } else {
          const link = document.createElement("a");
          link.href = "data:image/png;base64," + response.data.base64;
          link.download = screenshot.filename;
          link.click();
        }
      } else {
        setDownloadedScreenshot(null);
      }
    } catch (error) {
      setDownloadedScreenshot(null);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      e.target.disabled = false;
      e.target.innerText = "View Screenshot";
    }
  };

  const newRequestComponents = (message, testcase) => {
    return (
      <>
        <div className="d-flex">
          <span>{message}</span>
        </div>
        <div className="d-flex align-items-center mt-2">
          <button
            className="btn btn-sm btn-success px-3"
            onClick={() => {
              setApprovalRequestType(testcase.managerAccepted == 1 ? 2 : 1);
              toggleRequestApprovalModalVisibility();
            }}
          >
            <i className="bi bi-patch-check-fill me-1"></i> Request Approval
          </button>
        </div>
      </>
    );
  };

  const updateTestcase = async () => {
    try {
      updateBtnRef.current.disabled = true;
      updateBtnRef.current.innerText = "Please wait...";

      const response = await protectedAPI.post(
        "testcase/update/" + selectedTestCase.id,
        {
          projectId: selectedProject.id,
          moduleId: selectedModule.id,
          riskCategoryId: selectedRiskCategory.id,
          severity: selectedSeverity,
          possibleRisk,
          observations: observation,
          implications,
          recommendations: recommendation,
        }
      );
      if (response.status === 200) {
        showToast({
          type: "success",
          detail: response.data.message,
        });
        const updatedTestCases = testcases.map((testcase) => {
          if (testcase.id === selectedTestCase.id) {
            return response.data.testcase;
          }
          return testcase;
        });
        setTestCases(updatedTestCases);
        setSelectedTestCase(response.data.testcase);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      updateBtnRef.current.disabled = false;
      updateBtnRef.current.innerText = "Save Changes";
    }
  };

  useEffect(() => {
    if (selectedTestCase && visible) {
      getReviewRequestsByTestcaseId(selectedTestCase.id);
      getScreenshotsByTestcaseId(selectedTestCase.id);
    }
  }, [selectedTestCase]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  }, []);

  useEffect(() => {
    if (selectedProject && selectedTestCase && visible) {
      // * Get modules of selected project
      const projectId = selectedTestCase?.projectId;
      const _modules = modules[projectId] || [];
      const _selectedModule = _modules?.find(
        (module) => module?.id === selectedTestCase?.moduleId
      );
      setSelectedModule(_selectedModule);
      setFilteredModules(_modules);
    }
  }, [selectedProject, selectedTestCase]);

  useEffect(() => {
    if (selectedTestCase && visible) {
      setSelectedProject(
        projects.find((project) => project.id === selectedTestCase.projectId)
      );
      setSelectedRiskCategory(
        riskCategories.find(
          (category) => category.id === selectedTestCase.riskCategoryId
        )
      );
      const _selectedSeverity = _severityOptions.find(
        (severity) => severity.value == selectedTestCase.severity
      );
      setSelectedSeverity(_selectedSeverity?.value);
      setPossibleRisk(selectedTestCase.possibleRisk || "");
      setObservation(selectedTestCase.observation || "");
      setImplications(selectedTestCase.implications || "");
      setRecommendation(selectedTestCase.recommendations || "");
    }
    // console.log(selectedTestCase);
  }, [selectedTestCase]);

  return (
    <>
      <Sidebar
        header="Testcase Details"
        visible={visible}
        position="right"
        onHide={() => toggleSidePanelVisibility(null)}
        style={{ width: "40rem" }}
      >
        <TabMenu
          model={tabItems}
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
        />

        {activeTabIndex === 0 && (
          <>
            <div className="d-flex flex-column">
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2">
                    <div className="d-flex">
                      <span className="text-muted">Testcase Ref : </span>
                      <span className="fw-bold text-warning-emphasis ms-2">
                        {selectedTestCase?.ref}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="text-muted">Manager Accepted : </span>
                      <span className="fw-bold ms-2">
                        {selectedTestCase?.managerAccepted == 1 ? (
                          <span style={{ color: "limegreen" }}>
                            <i className="pi pi-fw pi-verified"></i> Yes
                          </span>
                        ) : (
                          "Not Yet"
                        )}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="text-muted">Director Accepted : </span>
                      <span className="fw-bold ms-2">
                        {selectedTestCase?.directorAccepted == 1 ? (
                          <span style={{ color: "limegreen" }}>
                            <i className="pi pi-fw pi-verified"></i> Yes
                          </span>
                        ) : (
                          "Not Yet"
                        )}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="text-muted">Testcase Created By : </span>
                      <span className="fw-bold ms-2">
                        {selectedTestCase?.createdByUser && (
                          <span>
                            {selectedTestCase?.createdByUser?.fullname || "--"}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="text-muted">
                        Testcase Last Updated By :{" "}
                      </span>
                      <span className="fw-bold ms-2">
                        {selectedTestCase?.createdByUser && (
                          <span>
                            {selectedTestCase?.updatedByUser?.fullname || "--"}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <span className="fw-bold">Edit Testcase Details</span>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex flex-column mb-3">
                  <span className="fw-normal text-muted pb-1">Project</span>
                  <Dropdown
                    value={selectedProject}
                    options={projects}
                    onChange={(e) => setSelectedProject(e.value)}
                    // itemTemplate={dropdownItemTemplate}
                    // valueTemplate={dropdownValueTemplate}
                    optionLabel="title"
                    filter
                    style={{ width: "100%" }}
                    placeholder="Select a Project"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex flex-column mb-3">
                  <span className="fw-normal text-muted pb-1">Modules</span>
                  <Dropdown
                    value={selectedModule}
                    options={filteredModules}
                    onChange={(e) => setSelectedModule(e.value)}
                    // itemTemplate={dropdownItemTemplate}
                    // valueTemplate={dropdownValueTemplate}
                    optionLabel="title"
                    filter
                    style={{ width: "100%" }}
                    placeholder="Select a Module"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 d-flex flex-column mb-3">
                  <span className="fw-normal text-muted pb-1">
                    Risk Categories
                  </span>
                  <Dropdown
                    value={selectedRiskCategory}
                    options={riskCategories}
                    onChange={(e) => setSelectedRiskCategory(e.value)}
                    // itemTemplate={dropdownItemTemplate}
                    // valueTemplate={dropdownValueTemplate}
                    optionLabel="title"
                    filter
                    style={{ width: "100%" }}
                    placeholder="Select a Category"
                  />
                </div>
                <div className="col-md-4 d-flex flex-column mb-3">
                  <span className="fw-normal text-muted pb-1">Severity</span>
                  <Dropdown
                    value={selectedSeverity}
                    options={_severityOptions}
                    onChange={(e) => setSelectedSeverity(e.value)}
                    itemTemplate={severityDropdownItemTemplate}
                    valueTemplate={severityDropdownItemTemplate}
                    filter
                    style={{ width: "100%" }}
                    placeholder="Select Severity"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex flex-column mb-3">
                  <span className="fw-normal text-muted pb-1">
                    Possible Risk
                  </span>
                  <InputTextarea
                    value={possibleRisk}
                    onChange={(e) => setPossibleRisk(e.target.value)}
                    className="w-100"
                    autoResize
                    rows={5}
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex flex-column mb-3">
                <span className="fw-normal text-muted pb-1">Observation</span>
                <InputTextarea
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                  className="w-100"
                  autoResize
                  rows={5}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex flex-column mb-3">
                <span className="fw-normal text-muted pb-1">Implications</span>
                <InputTextarea
                  value={implications}
                  onChange={(e) => setImplications(e.target.value)}
                  className="w-100"
                  autoResize
                  rows={5}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex flex-column mb-3">
                <span className="fw-normal text-muted pb-1">
                  Recommendation
                </span>
                <InputTextarea
                  value={recommendation}
                  onChange={(e) => setRecommendation(e.target.value)}
                  className="w-100"
                  autoResize
                  rows={5}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex flex-column mb-3">
                <button
                  className="btn btn-success"
                  onClick={updateTestcase}
                  ref={updateBtnRef}
                >
                  <i className="bi bi-save me-1"></i> Save Changes
                </button>
              </div>
            </div>
          </>
        )}
        {activeTabIndex === 1 && (
          <>
            {selectedTestCase?.reviewRequests?.every(
              (request) => request.status == 2
            ) && (
              <>
                {user?.groupId == 14 ? (
                  <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2">
                    {newRequestComponents(
                      "All Manager approval requests have been rejected. Request Project Manager approval again.",
                      selectedTestCase
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {selectedTestCase?.managerAccepted == 1 &&
            reviewRequestsOfType2?.length == 0 ? (
              <>
                {user?.groupId == 14 ? (
                  <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2">
                    {newRequestComponents(
                      "Manager has accepted the request. Request Director approval.",
                      selectedTestCase
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {selectedTestCase?.managerAccepted == 1 &&
            reviewRequestsOfType2?.length > 0 &&
            reviewRequestsOfType2?.every((request) => request.status == 2) ? (
              <>
                {user?.groupId == 14 ? (
                  <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2">
                    {newRequestComponents(
                      "All Director approval requests have been rejected. Request Director approval again.",
                      selectedTestCase
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {selectedTestCase?.reviewRequests?.length > 0 ? (
              <>
                {selectedTestCase?.reviewRequests?.map((request) => (
                  <div
                    className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2"
                    key={request.id}
                  >
                    <div className="d-flex flex-column">
                      <span className="fw-medium pb-1 me-2">
                        {request.requestType == 1
                          ? "Project Manager Approval Request"
                          : "Director Approval Request"}
                      </span>
                      <small className="mt-1">
                        Time : {formatDate(request.createdAt)}
                      </small>
                      <small className="mt-1">
                        Requested By :{" "}
                        {request.createdByDetails?.fullname || "--"}
                      </small>
                      <small className="mt-1">
                        Approval From :
                        {request.assignees?.map((assignee) => (
                          <small
                            key={assignee.id}
                            className="badge text-bg-secondary fw-normal mx-1"
                          >
                            {assignee.userDetails?.fullname ||
                              assignee.userDetails?.nickname}
                          </small>
                        )) || "--"}
                      </small>
                      <small>
                        Status :{" "}
                        <span
                          className={`fw-medium text-${
                            request.status == 0
                              ? "warning"
                              : request.status == 1
                              ? "success"
                              : "danger"
                          }`}
                        >
                          {request.status == 0
                            ? "Pending"
                            : request.status == 1
                            ? "Approved"
                            : "Rejected"}
                        </span>
                      </small>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <button
                        className="btn btn-sm tc-btn border border-dark-subtle px-3"
                        onClick={() => {
                          setSelectedReviewRequest(request);
                          toggleReviewRequestModalVisibility();
                        }}
                      >
                        <i className="bi bi-eye me-1"></i> View Request
                      </button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              selectedTestCase && (
                <>
                  {user?.groupId == 14 ? (
                    <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2">
                      {newRequestComponents(
                        "No approval requests found. Request Project Manager approval.",
                        selectedTestCase
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2">
                      <span>No approval requests found.</span>
                    </div>
                  )}
                </>
              )
            )}
          </>
        )}
        {activeTabIndex === 2 && (
          <>
            <div className="d-flex flex-column">
              {screenshots.length > 0 ? (
                <>
                  {screenshots.map((screenshot) => (
                    <div
                      key={screenshot.id}
                      className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2"
                    >
                      <div className="d-flex flex-column">
                        <span className="fw-bold pb-1 me-2 text-break">
                          {screenshot.filename}
                        </span>
                        <small className="mt-1">
                          Time : {formatDate(screenshot.createdAt)}
                        </small>
                        <small>
                          Uploaded by :{" "}
                          <span className="fw-medium">
                            {screenshot.user?.fullname || "--"}
                          </span>
                        </small>
                        <small>
                          Comments :{" "}
                          <p className="text-wrap">
                            {screenshot.comment || "--"}
                          </p>
                        </small>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <button
                          className="btn btn-sm btn-info px-3 me-1"
                          onClick={(e) => {
                            downloadScreenshot(screenshot, e);
                          }}
                        >
                          <i className="bi bi-eye me-1"></i> View
                        </button>
                        <button
                          className="btn btn-sm btn-success px-3"
                          onClick={(e) => {
                            downloadScreenshot(screenshot, e, true);
                          }}
                        >
                          <i className="bi bi-download me-1"></i> Download
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="d-flex flex-column border-4 rounded ps-3 py-3 border-start border-secondary dark-gradient-bg mb-2">
                  <span>No screenshots found.</span>
                </div>
              )}
            </div>
          </>
        )}
      </Sidebar>
      <RequestApprovalModal
        visibleRequestApprovalModal={visibleRequestApprovalModal}
        toggleRequestApprovalModalVisibility={
          toggleRequestApprovalModalVisibility
        }
        selectedTestCase={selectedTestCase}
        approvalRequestType={approvalRequestType}
        testcases={testcases}
        setTestCases={setTestCases}
        setSelectedTestCase={setSelectedTestCase}
        checkedTestCases={[]} // * not using in this component
      />
      <ViewReviewRequestModal
        visibleReviewRequestModal={visibleReviewRequestModal}
        toggleReviewRequestModalVisibility={toggleReviewRequestModalVisibility}
        selectedTestCase={selectedTestCase}
        testcases={testcases}
        setTestCases={setTestCases}
        setSelectedTestCase={setSelectedTestCase}
        selectedReviewRequest={selectedReviewRequest}
        setSelectedReviewRequest={setSelectedReviewRequest}
        setReviewRequestsOfType2={setReviewRequestsOfType2}
      />
      <ViewScreenshot
        viewScreenshotDialogVisible={viewScreenshotDialogVisible}
        setViewScreenshotDialogVisible={setViewScreenshotDialogVisible}
        screenshotImage={downloadedScreenshot}
      />
    </>
  );
}

export default TestCaseSidePanel;
